import { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router";
import ReactGa from "react-ga";
import HomePage from "./pages/HomePage/HomePage";
import OrientationModal from "./Components/OrientationModal/OrientationModal";
import GifPage from "./pages/GifPage/GifPage";
import Outro from "./pages/Outro/Outro";

const TRACKING_ID = "G-PV3X4PP1HL";
ReactGa.initialize(TRACKING_ID);

function App() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      });
    };
  }, [height, width]);

  return (
    <div className="App">
      {width < height && <OrientationModal />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/play" element={<GifPage />} />
        <Route path="/final-step" element={<Outro />} />
      </Routes>
    </div>
  );
}

export default App;
