import React from "react";
import "./OrientationModal.css";
import rotateIcon from "../../assets/images/Rotate_phone.png";

export default function OrientationModal() {
  return (
    <div className="overlay">
      <div className="orientation-modal d-flex">
        <div className="image-container">
          <img src={rotateIcon} />
        </div>
        <h2>Please rotate your device to landscape mode</h2>
        {/* <button className="reload-btn" onClick={() => window.location.reload()}>
          Reload
        </button> */}
      </div>
    </div>
  );
}
