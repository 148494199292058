import React from "react";
import ReactGa from "react-ga";

import "./Outro.css";
import SideBarTitle from "../../Components/SideBarTitle/SideBarTitle";
import FooterLogo from "../../Components/FooterLogo/FooterLogo";
import MainButton from "../../Components/MainButton/MainButton";
import QRCode from "../../assets/images/AZ-qrcode.jpg";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function Outro() {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
  }, []);

  return (
    <div className="homePage outerPage">
      {/* <SideBarTitle /> */}
      <div className="main-container">
        <div className="outer-content">
          <div className="qr-content-container">
            <div className="qr-container">
              <img src={QRCode} />
            </div>
            <p>
              Full prescribing information of (Kombiglyze®) is available upon
              request from AstraZeneca or kindly refer to the insert leaflet or
              access it via the QR code.
            </p>
          </div>
          <p>
            References: 1. Jadzinsky M et al. Diabetes Obes Metab.
            2009;11(6):611-622. 2.Gokulnath, et al. Diabetes Obes Int J 2021;
            6(1): 000242. 3. Kombiglyze XR. Summary of product characteristics.
            4. Goke B, etal. IntJ ClinPract. 2010;64(12):1619-31
          </p>
        </div>
      </div>
      <MainButton text="New Game" onClick={() => navigate("/")} />
      <FooterLogo showText={true} />
    </div>
  );
}
