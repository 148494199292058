import React from "react";
import "./SideBarTitle.css";

export default function SideBarTitle() {
  return (
    <div className="sideBar-title">
      <p>KXR-393-iDC-0524-05-2025-IRQ</p>
    </div>
  );
}
