import React from "react";
import ReactGa from "react-ga";

import "./HomePage.css";
import MainButton from "../../Components/MainButton/MainButton";
import SideBarTitle from "../../Components/SideBarTitle/SideBarTitle";
import FooterLogo from "../../Components/FooterLogo/FooterLogo";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export default function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
  }, []);

  return (
    <div className="homePage">
      {/* <SideBarTitle /> */}
      <main className="main-container">
        <div className="main-content">
          <h1 id="text3d" className="main-title hide">
            can you hit
            <br />
            them
            <br /> all at once?
          </h1>
          <MainButton
            className="home-btn"
            onClick={() => navigate("/play")}
            text="Start"
          />
        </div>
      </main>
      <FooterLogo />
    </div>
  );
}
