import React, { useEffect, useState } from "react";
import ReactGa from "react-ga";

import "./GifPage.css";
import SideBarTitle from "../../Components/SideBarTitle/SideBarTitle";
import FooterLogo from "../../Components/FooterLogo/FooterLogo";
import video from "../../assets/video/Comp5.mp4";
import videoWebm from "../../assets/video/Comp5.webm";
import videoMov from "../../assets/video/Comp5.mov";
import videoFrame from "../../assets/images/video-frame.png";
import MainButton from "../../Components/MainButton/MainButton";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

export default function GifPage() {
  const navigate = useNavigate();
  const [toggleGif, setToggleGif] = useState(false);
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [loading, setLoading] = useState(true);

  function toggleGifHandler() {
    setToggleGif(true);
    let video = document.querySelector(".video-element");
    let imageFrame = document.querySelector(".gif-image");
    let nextBtn = document.querySelector(".next-btn");

    video.classList.remove("hide");
    video.controls = false;
    video.load();
    video.onplay = () => {
      // imageFrame.classList.add("fade");
      setTimeout(() => {
        imageFrame.classList.add("hide");
      }, 100);
    };
    video.play();
    video.onended = () => {
      nextBtn.classList.add("show");
      setTimeout(() => {
        // setShowNextBtn(true);
        nextBtn.classList.remove("fade");
      }, 1200);
    };
  }

  useEffect(() => {
    ReactGa.pageview(window.location.pathname);
  }, []);

  return (
    <div className="gif-page">
      {loading && <LoadingSpinner />}
      {/* <SideBarTitle /> */}
      <div onClick={(e) => e.stopPropagation()} className="gif-container">
        <img
          className="gif-image"
          src={videoFrame}
          alt="gif-image-placeholder"
          onLoad={() => setLoading(false)}
        />

        <video className="video-element" playsInline>
          <source src={video} type="video/mp4" />
          <source src={videoWebm} type="video/webm" />
          <source src={videoMov} type="video/mov" />
        </video>

        {/* {showNextBtn && ( */}
        <MainButton
          onClick={() => navigate("/final-step")}
          className="next-btn fade"
          text="Next"
        />
        {/* )} */}
        {!toggleGif && (
          <MainButton
            className="play-btn"
            text="Click To Hit"
            onClick={toggleGifHandler}
          />
        )}
      </div>
      <FooterLogo />
    </div>
  );
}
