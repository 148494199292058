import React from "react";
import AZLogo from "../../assets/images/AZ-logo.png";
import "./FooterLogo.css";
import SideBarTitle from "../SideBarTitle/SideBarTitle";

export default function FooterLogo({ showText = false }) {
  return (
    <div className="footer-container">
      {showText && <SideBarTitle />}
      <div className="logo-container">
        <div>
          <img src={AZLogo} className="logo" />
        </div>
        {showText && (
          <div className="footer-text">
            AstraZeneca Near East
            <br />
            Sweifieh, BLDG 19 Abdelraheem Al-Haj Mohammad street,
            <br />
            P.O. Box: 17788, Zip Code 11195 Amman - Jordan; Tel: +96265827580
            <br />
            For any adverse event report, product quality complaint or medical
            information request related to AZ products,
            <br />
            you can complete the web-based form accessible 24/7 via the URL:
            http://contactazmedical.astrazeneca.com
            <br />
            For medical information requests, you can alternatively send to:
            medinfo-ne@astrazeneca.com
            <br />
            For adverse events reporting, you can alternatively send directly
            to: Patientsafety.ne@astrazeneca.com
            <br />
            Or Contact our office on Tel: +96265827580 or +9611385534
            <br />
          </div>
        )}
      </div>
    </div>
  );
}
