import React from "react";
import "./MainButton.css";

export default function MainButton({
  text = "",
  className = "",
  onClick = () => {},
}) {
  return (
    <div className={`btn-container ${className}`}>
      <button onClick={onClick} className="main-btn">
        {text}
      </button>
    </div>
  );
}
